import React from 'react'
import Zapier from '../../../components/Integrations/IntegrationsDetails/Zapier'
import PageSeo from '../../../components/SEO'

function index() {
    return (
        <div>
            <PageSeo pageTitle="Zapier"/>
            <Zapier/>
        </div>
    )
}

export default index
